
import './form.css';
import { useState, useRef, useEffect } from 'react';
import { CustomTable, LargeTextBox, MultipleTextBox, TextBox, YearPicker, GroupTextBox, CheckboxField, DropdownList, HTMLTextBox } from './inputfields.js';
import { UploadImages, ImageUpload ,Image} from './imageUpload.js';
import { Button } from '../Buttons.js';
import { FaArrowLeft,FaEdit } from 'react-icons/fa';
import { PageLine } from '../pageline';
import { createPortal } from 'react-dom';
import { Popup } from '../Popup';
const components = {
    TextBox: TextBox,
    YearPicker: YearPicker,
    UploadImages: UploadImages,
    MultipleTextBox: MultipleTextBox,
    LargeTextBox: LargeTextBox,
    GroupTextBox: GroupTextBox,
    Image: Image,
    ImageUpload:ImageUpload,
    CheckboxField: CheckboxField,
    DropdownList: DropdownList,
    HTMLTextbox: HTMLTextBox
}


export function Form({state:_state='edit', data, close,parent,buttons={top:true,bottom:true} }) {
    /*
    view
    edit
    disabled
    */
    const [template, setTemplate] = useState([])
    const [state,setState] = useState(_state)
    const [warning,setWarning] = useState(0)
    const [formFields,setFormFields] = useState(null)

    useEffect(() => {
        console.trace(data)
        setTemplate(data.fields.sort((a, b) => {
            if (!isNaN(a.index) && !isNaN(b.index)) {
                return a.index - b.index
            }
            return 1
        }))
    }, [data.fields])
    function closeForm(){

        setState('view')
        setFormFields(null)
        if(close) {close()}
    }
    const formRefs = useRef({})
    useEffect(()=>{
       const f =  template.map(t => {
            const value = (data.data) ? data.data[t.id] : t.defaultValue;
            formRefs.current[t.id] = value;
            
            if (t.type === 'fixed') {
                return null
            }
            if (t.id === 'defaultValue') {
                console.log(data,value)
                t.type = data.data.type
            }
            const Com = components[t.type]
   
            const update = function (val) {
             
                setWarning(1)
                formRefs.current[t.id] = val
            }
            if (t.id === 'type' && t.type === "DropdownList") {
                return (<Com disabled={(state !== 'edit')?true:false} parent={parent} update={update} label={capitalize(t.name)} value={value} key={t.id} options={Object.keys(components)} />)
            }

            return (<Com disabled={(state !== 'edit')?true:false} parent={parent} update={update} label={capitalize(t.name)} value={value} key={t.id} data={t} />)
        })
        setFormFields(f)
    },[template,state])
   
    function SetFields() {
            return template.map(t => {
                const value = (data.data) ? data.data[t.id] : t.defaultValue;
                formRefs.current[t.id] = value;
                
                if (t.type === 'fixed') {
                    return null
                }
                if (t.id === 'defaultValue') {
                    console.log(data,value)
                    t.type = data.data.type
                }
                const Com = components[t.type]
       
                const update = function (val) {
                    formRefs.current[t.id] = val
                }
                console.log((state !== 'edit'))
                if (t.id === 'type' && t.type === "DropdownList") {
                    return (<Com disabled={(state !== 'edit')?true:false} parent={parent} update={update} label={capitalize(t.name)} value={value} key={t.id} options={Object.keys(components)} />)
                }

                return (<Com disabled={(state !== 'edit')?true:false} parent={parent} update={update} label={capitalize(t.name)} value={value} key={t.id} data={t} />)
            })
 

    }
    function handle(event) {
        event.preventDefault()
        event.stopPropagation()
        return (async () => {
            await data.update(formRefs.current)
            return true
        })()
    }
    function EditButton(){
        return (
            <div className='btn-container'>
                <div/>
                <div><Button click={()=>{setState('edit')}} style="alternate" text="edit" Icon={FaEdit} /></div>
            </div>
        )
    }
    function CloseWarning(){
        return (
         createPortal(
        <Popup 
            title="Warning" 
            size="small"
            onClose={() => setWarning(0)} 
            content={(
            <div>
                <div>this document is not saved, are you sure you would like to return?</div>
                <div style={{ display: 'flex', justifyContent:'center', flexWrap: 'wrap' }}>  
                <Button click={async (e) => {setWarning(0);closeForm()}} text="Go Back" />
                <Button click={async (e) => {setWarning(0)}} style="alternate" text="Cancel" />
                </div>
            </div>      
        )} />,
        document.body)
        )
    }
    console.log(warning)
    function FormButtons() {
        return (
            <div className='btn-container'>
                {(warning===2) && <CloseWarning/>}
                <div><Button click={()=>{if(warning===1){setWarning(2);return};closeForm()}} style="alternate" text="Terug" Icon={FaArrowLeft} /></div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>  
                    <Button click={async (e) => { await handle(e) }} text="Opslaan" />
                    <Button click={async (e) => { await handle(e); closeForm()}} text="Opslaan en Terug" />
                </div>
            </div>
        )
    }

    return (
        <div className={"form "+state} >
            <div className="input-form">
                <form onSubmit={handle}>
                {state === 'view' && <EditButton/>}
                   {(state === 'edit' && buttons.top) && <FormButtons />}
                   <PageLine padding={'2px'}/>
                    {formFields}
                    {(state === 'edit' && buttons.bottom) && <FormButtons />}
                </form>

            </div>
        </div>
    )
}

function capitalize(str) {
    const f = str.charAt(0)
    const r = str.slice(1)
    return f.toUpperCase() + r
}
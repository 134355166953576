import {Collection,CollectionFields,CollectionForm } from './resources/index';
import './Home.css';

import {useState} from 'react'
import { DocumentForm } from './resources/elements/form/formCollection';
export function CMSSetup() {
  
    const [state, setState] = useState(null)
    const [changeFields, setChangeFields] = useState(null)
    const options = [{ 
        title: "Edit", 
        func: (e, pjID) => { setChangeFields( pjID) } 
    }]
    console.log(state)
    return (
        <div className="project-types">
            {(!state && !changeFields) && (
                    <Collection title="Setup" path="forms" click={setState} options={options} />
            )}
               {(!state && changeFields) && <CollectionFields title={'Update Project Type'} path={'forms/entries/'+changeFields}  />}
            {state && (
                <DocumentForm path={'forms' } id={state} close={() => { setState(null) }} />
            )}

        </div>
    )
}

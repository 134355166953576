import { FaFacebook, FaFacebookF, FaInstagram, FaMailBulk, FaPhone, FaWhatsapp, MdOutlineEmail,FaPlusCircle,FaTrashAlt,FaWindowClose } from 'react-icons/fa';
import './Buttons.css';

export function AddIcon(props) {
    return <Icon  {...props} Type={FaPlusCircle}/>
}
export function DeleteIcon(props) {
    return <Icon  {...props} Type={FaTrashAlt}/>
}
export function CloseIcon(props) {
    return <Icon  {...props} Type={FaWindowClose}/>
}
function Icon({ Type,size:s=28,click,style,active }) {

    function getClass(){
        const str = style+(active)?'active':''
        return "icon "+str
    }
    return (
        <div className={getClass()} onClick={click} >
            <Type size={s} />
        </div>
    );

}

export function Button({click,htmlFor,text,Icon,active=true,style:st="standard"}){
    function getClass(){
        const act = (active)?'active':''
        const str = st+' '+act
        return "button "+str
    }
    return (
        <div className={getClass()} onClick={click}>
            <div >
            {Icon && <Icon/>}
            {htmlFor && (<label htmlFor={htmlFor}>{text}</label>)}
            {!htmlFor && <button>{text}</button>}
            </div>
        </div>
    )
}
import {Collection,CollectionFields, Form } from './resources/index';
import './projects.css';

import {useEffect, useState} from 'react'
export function CMSProjects() {
   const [typeState, setTypeState] = useState(null)
    const [state, setState] = useState(null)
    const [changeFields, setChangeFields] = useState(null)

    const options = [{ 
        title: "Edit", 
        func: (e, pjID) => { setChangeFields(pjID) } 
    }]
    function openProject(e,data){
        console.log(e,data)
        setState(data)
    }
    return (
        <div class="project-types">
            {(!state && !changeFields) && (
                <div>
                    <Collection title="Project Types" path="projects" click={setTypeState} options={options} />
                    {typeState && <Collection key={"projectskey" + typeState}  path={'projects/entries/' + typeState} click={openProject} />}
                </div>
            )}
            {(!state && changeFields) && <CollectionFields title={'Update Project Type'} path={'projects/entries/'+changeFields} />}
            {state && (
                <Form data={state} id={state.id} close={() => { setState(null) }} />
            )}

        </div>
    )
}


import { useState, useEffect } from 'react';
import { AdminDatabase } from './firebase-admin.js';

export function getDefaultFields(title = 'entry') {
    const n = 'New ' + title;
    return [
        { id: 'id', name: 'ID', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'index', name: 'Index', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'amount', name: 'Amount', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'name', name: 'Admin Naam', type: 'TextBox', defaultValue: n, columnVisible: true },
    ]
}

export class collectionObject {
    constructor({ path, onUpdate, filter, target = "entries", fields: f }) {
        this.path = path;
        this.onUpdate = onUpdate;
        this.filter = filter;
        this.target = target;
        this.fields = f || [];
        this.data = null;
        this.loading = true;

        // Initialize a Promise that will be resolved when the object is ready
        this.ready = new Promise((resolve, reject) => {
            this.resolveReady = resolve;
            this.rejectReady = reject;
        });

        // Initialize
        this.initialize();
    }
    async initialize() {
        try {
            // fields
            if (!this.fields.length) {
                const fieldsFromDB = await AdminDatabase.getCollection(`${this.path}/fields`);
                this.fields = [...getDefaultFields(), ...fieldsFromDB];
            }

            // data
            let fetchedData = [];
            if (!this.filter || this.filter[2].length > 0) {
                fetchedData = await AdminDatabase.getCollection(this.path + '/' + this.target, this.filter);
            }

            // make object
            const l = fetchedData.length;
            const _data = [];
            for (let i = 0; i < l; i++) {
                const e = fetchedData[i];
                const obj = new documentObject({ data: e, fields: this.fields, path: this.path, id: e.id, type: "collection" })
                await obj.ready
                _data.push(obj);
            }
            this.data = _data;
            this.loading = false; // Data has been fetched
            
            this.resolveReady();
        } catch (error) {
            console.error("Failed to fetch data:", error);
            this.rejectReady(error);
        }
    }

    onDataChange() {
        console.log('data change')
        if (this.data && this.onUpdate) {
            console.log('Collection updated')
            const updateData = this.data.map(d => d.id);
            this.onUpdate(updateData,this.data);
        }
    }

    async add() {
        if (!this.fields.length) return;
        const entryData = this.fields.reduce((acc, field) => {
            if (field.defaultValue !== undefined) {
                acc[field.id] = field.defaultValue;
            }
            return acc;
        }, {});

        const doc = await AdminDatabase.addDoc(this.path + '/' + this.target, entryData);
        console.log(doc,entryData)
        this.data.push(new documentObject({ data: entryData, fields: this.fields, path: this.path, id: entryData.id, type: "collection" }));
        this.onDataChange();
    }

    async delete(projectIds) {
        const ids = Array.isArray(projectIds) ? projectIds : [projectIds];
        await AdminDatabase.deleteDocs(this.path + '/' + this.target, ids);
        this.data = this.data.filter(d => !ids.includes(d.id));
        this.onDataChange();
    }

    async update(dataToUpdate) {
        await AdminDatabase.updateDocs(this.path + '/' + this.target, dataToUpdate);
        this.data = this.data.map(d => (dataToUpdate.id === d.id ? dataToUpdate : d));
        this.onDataChange();
    }
}




export function CollectionObject(props) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        (async () => {
            const _data = new collectionObject(props)
            await _data.ready;
            setData(_data)
            setLoading(false); // Data has been fetched
        })();
    }, []);

    if (loading) {
        return null; // Or some loading component
    }
    return data;

}

//////////////////////////////////////////////////////////////////////////////////////////////
/************************************ Document Object ***************************************/
//////////////////////////////////////////////////////////////////////////////////////////////
export function DocumentObject(props) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const _data = new documentObject(props)
            await _data.ready;
            setData(_data)
            setLoading(false); // Data has been fetched
        })();
    }, []);
    if (loading) {
        return null; // Or some loading component
    }
    return data;

}


export class documentObject {
    constructor({ data: d = null, fields: f = null, path, id, title, target = "entries", type = 'document',onUpdate }) {
        this.data = d;
        this.fields = f;
        this.path = path;
        this.id = id;
        this.title = title;
        this.target = target;
        this.loading = true;
        this.type = type
        this.onUpdate = onUpdate
console.log(this.path)

        // Initialize a Promise that will be resolved when the object is ready
        this.ready = new Promise((resolve, reject) => {
            this.resolveReady = resolve;
            this.rejectReady = reject;
        });

        // Initialize
        this.initialize();
    }
    onDataChange() {
        if (this.data && this.onUpdate) {
            console.log('document data change')
            this.onUpdate(this.id,this);
        }
    }
    async initialize() {

        const getData = async () => {
            console.log(this.id)
            if (!this.id) {
                if (!this.fields.length) { return };

                const entryData = this.fields.reduce((acc, field) => {
                    if (field.defaultValue !== undefined) {
                        acc[field.id] = field.defaultValue;
                    }
                    return acc;
                }, {});
                console.log('add doc',entryData)
                const data = await AdminDatabase.addDoc(this.path + '/' + this.target, entryData);
             
                this.id = data.id
                console.log('add doc',this.id)
                return data
            }
            return await AdminDatabase.getDoc(this.path + '/' + this.target, this.id);
        }

        //////////
    
            if (!this.fields) {
                const f = (this.type === 'collection') ? this.path + '/fields' : this.path + '/entries/' + this.id + '/fields'
                console.log(f)
                const fields = await AdminDatabase.getCollection(f)
                console.log(fields)
                if (fields.empty) {
                    console.error('ERROR NO FIELDS FOUND FOR FORM');
                    return;
                }
                this.fields = [...getDefaultFields(this.title), ...fields];
            }

            if (!this.data) {
                this.data = await getData()
             
            }
            this.loading = false;
            this.resolveReady();



    }

    async update(dataToUpdate) {
        await AdminDatabase.updateDoc(`${this.path}/${this.target}`, this.id, dataToUpdate);
        this.data = { ...this.data, ...dataToUpdate };
        this.onDataChange();
    }

    get(str, i) {
        if (!this.fields || !this.data) {
            console.error(['element not initialized, no data and fields in this document object', this]);
            return;
        }
        const f = this.fields.find(field => field.name === str);
        if (f) {
            return this.data[f.id];
        }
        return [];
    }
}




/*
export function CollectionObject({ path, onUpdate, filter, children = null,target="entries",fields:f }) {

    const [data, setData] = useState(null);
    const [fields, setFields] = useState(f);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                //fields
                if(!fields){
                    const fieldsFromDB = await AdminDatabase.getCollection(`${path}/fields`);
                    const mergedFields = [...getDefaultFields(), ...fieldsFromDB];
                    setFields(mergedFields)
                }
                //data
                let fetchedData = [];
                if (!filter || filter[2].length > 0) {
                    fetchedData = await AdminDatabase.getCollection(path+'/'+target, filter);
                }
console.log(fetchedData)
                //make object
                const l = fetchedData.length;
                const _data=[]
                for(let i=0;i<l;i++){
                    const e = fetchedData[i]
                    _data.push(  DocumentObject({ data:e, fields: fields, path:path, id:e.id }) )
                }
              console.log('DATA:',_data)
                setData(_data);
                setLoading(false); // Data has been fetched
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        })();
    }, [path, filter]);
    useEffect(()=>{
        console.log(data)
        if(data){
        const updateData=data.map(d=>d.id)
        console.log('updated data in collection data: '+updateData.join(', '))
        if(onUpdate){
            onUpdate(updateData)
        }
        }

    },[data])
  
    async function add() {
        if (!fields) return;

        const entryData = fields.reduce((acc, field) => {
            if (field.defaultValue !== undefined) {
                acc[field.id] = field.defaultValue;
            }
            return acc;
        }, {});

        const doc = await AdminDatabase.addDoc(path+'/'+target, entryData);
        setData(prevData => ([...prevData, doc]))
    };

    async function deleteItem(projectIds) {
        const ids = Array.isArray(projectIds) ? projectIds : [projectIds];
        await AdminDatabase.deleteDocs(path+'/'+target, ids);
        setData(prevData => (prevData.filter(d => !ids.includes(d.id))))
    };

    async function update(dataToUpdate) {
        await AdminDatabase.updateDocs(path+'/'+target, dataToUpdate);
        setData(prevData => (prevData.map(d => (dataToUpdate.id === d.id ? dataToUpdate : d))))
    };

    const collectionObject = {
        fields: fields,
        data: data,
        add,
        delete: deleteItem,
        update,
        get default() {
            return data;
        }
    };

    if (loading) {
        return null; // Or some loading component
    }

    if (children) {
        return children(collectionObject);
    }
    return collectionObject;
}

export function DocumentObject({ data: d = null, fields: f = null, path, id, title, children = null, target = "entries" }) {
    const [data, setData] = useState(d);
    const [fields, setFields] = useState(f);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            if (!fields) {
                const f = await AdminDatabase.getCollection(path + '/entries/' + id + '/fields');
                if (f.empty) {
                    console.error('ERROR NO FIELDS FOUND FOR FORM');
                }
                setFields([...getDefaultFields(title), ...f]);
            }
            if (!data) {
                const e = await AdminDatabase.getDoc(path + '/' + target, id);
                setData(e);
            }
            setLoading(false)

        })();
    }, [path, id]);

    async function update(dataToUpdate) {
        await AdminDatabase.updateDoc(path + '/' + target, id, dataToUpdate);
    };

    function get(str, i) {
        if (!fields || !data) {
            console.error(['element not initialized, no data and fields in this document object', this])
            return
        }
        const f = fields.find(field => field.name === str);
        if (f) {
            return data[f.id];
        }
        return [];
    };
    const documentObject = {
        data,
        fields,
        update,
        get,
        get default() {
            return data;
        }
    };

    if (loading) {
        return null
    }


    if (children) {
        return children(documentObject);
    }

    return documentObject
}
*/
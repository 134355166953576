import './table.css';
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import './modal.css';
import { createPortal } from 'react-dom';
import { Checkbox } from './checkbox';
import { DropdownMenu } from './dropdown';
import { Popup } from './Popup';
import { Button } from './Buttons';
import { getImageUrl } from '../firebase/firebase-verify';


export function DragTable({click, title, data,  options = [] }) {
    //  const [fields,setFields] = useState(fields)
    const [selectedRows, setSelectedRows] = useState([]);
    const [rows, setRows] = useState();
    const [highlight, setHighlight] = useState();

    /////////////////////////////////////////

  
    //Click
    const handleClick = (click) ? async (e, p,i) => {
        console.log(e,p,i)
        await click(p.id,p,e)
        setHighlight(i)
    } : false

    //Add row
    const handleAdd = (data.add) ? async (e,p) => {
        await data.add()
        setRowData()
    } : false

    //Update
    const handleUpdate = (data.update) ? async (e, d) => {
        await data.update(e, d)
        setRowData()
    } : false

    //Delete row
    const handleDelete = (data.delete) ? async (e, del = selectedRows, c) => {
        await data.delete(del)
        setRowData()

        e.stopPropagation()
        e.preventDefault()
    } : false

    /////////////////////////////////////////

    useEffect(() => {
        setRowData()
    }, [data])
    function setRowData(){
        console.log('set new rows')
        const d = (data.data)?data.data.slice():[]
        
        setRows(d.sort((a, b) => {
            if (!isNaN(a.index) && !isNaN(b.index)) {
                return a.index - b.index
            }
            return 1
        }))
    }

    /////////////////////////////////////////
    //Select row
    function handleCheckboxChange(e, projectId) {
        if (selectedRows.includes(projectId)) {
            setSelectedRows(selectedRows.filter((id) => id !== projectId));
        } else {
            setSelectedRows([...selectedRows, projectId]);
        }

    }

    ///Headers
    const [headers, setHeaders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(false);



    useEffect(() => {
        function handleColumnSelect(event, fieldId) {
            const column = event.target.value;

            const find = headers.find(c => {
                return (c[0] === fieldId)
            })

            if (find) {
                setHeaders(headers.filter((col) => col[0] !== fieldId));
            } else {
                setHeaders([...headers, [fieldId, column]]);
            }
        }
        const t = data.fields.map((h, i) => {
            const indi = h.id
            return (
                <div>
                    <Checkbox
                        key={'field' + i}
                        value={h.name}
                        type='slider'
                        checked={headers.findIndex(c => c[0] === indi) >= 0}
                        onChange={(e) => { handleColumnSelect(e, indi) }}
                    />
                </div>
            )
        })

        setModalContent((<div className={'header' + headers.length}>{t}</div>))
    }, [headers, data.fields])

    useEffect(() => {
        setHeaders((()=>{
            const el = []
  
            data.fields.forEach(f => {
      
                if (f.columnVisible) {
                    el.push([f.id, f.name, f.type])
                }
            })
            return el
        })())
    }, [data.fields])
    
    function getRowContent(project) {
    
        return headers.map((h, i) => {
        
            const k = project.id + 'fgd' + i
            const el = project.data[h[0]]
            const value = check(el)
       
            if (h[2] === "ImageUpload") {
                const element = (el)?el.medium:getImageUrl(project.id,'small')
                return <td className='img-cell'  key={k}><div style={{height:'153px'}}><img src={element}></img></div></td>
            }
            if (h[2] === "UploadImages") {
                console.log(el)
               return (
                <td className='img-cell'  key={k}>
                    <div style={{display:'flex'}}>
                {isObject(el) && Object.values(el)[0].map(e=>{
                    const ele = getImageUrl(e,'small')
                    return <div style={{ width:'60px',height:'60px',padding:'4px'}} ><img  style={{ objectFit:'cover'}} src={ele}></img></div>
                })}
                </div>
                </td>)
            }
          
            if (value === true || value === false) {
                return <td style={{ opacity: 0.4 }} key={k}><Checkbox float="center" checked={value} onChange={(e) => { }} /></td>
            }
            return <td key={k}>{value}</td>
        })

        function check(p) {
            if (isObject(p)) {
                if (isArray(p)) {
                    if (!isObject(p[0]) && !isArray(p[0])) {
                        return p.join(', ')
                    }
                }
                const v = Object.keys(p)
                const n = v.map(k => {
                    return p[k]
                })
                return n.join(', ')

            };
            return p
        }
    }


    ///Dragging the Rows
    const [draggedRow, setDraggedRow] = useState(null);
    const [topOffset, setTopOffset] = useState([0, 0]);

    function saveOrder() {
        const up = {}
        rows.forEach((p, i) => {
            up[p.id] = { index: i }
        })
        data.update(up)
    }

    function handleDragStart(e, project) {
        setDraggedRow(project);
        setTopOffset([e.clientY, 0])

    };
    function handleDrop(e) {
        if (draggedRow) {
            setTopOffset([e.clientY, 0])
            setDraggedRow(null);
            saveOrder()
        }
    };
    function handleDragOver(e, i) {
        if (draggedRow) {
            const nt = e.clientY - topOffset[0]
            if (e.clientY === 0) return

            const proj = draggedRow
            const oli = rows.indexOf(proj)
            if (oli === 0 && nt < 0) return
            if (oli === rows.length - 1 && nt > 0) return;
            const ntabs = Math.abs(nt)
            const inde = nt / ntabs

            const element = e.currentTarget.parentElement
            const hoverElement = (inde < 0) ? element.previousSibling : element.nextSibling

            const he = hoverElement.clientHeight//clientHeight
            if (ntabs >= he) {
                const newProjects = [...rows];
                newProjects.splice(oli, 1);
                newProjects.splice(oli + inde, 0, proj);
                setRows(newProjects);
                setTopOffset([e.clientY, 0])
                return
            }
            setTopOffset([topOffset[0], nt])
        }
    };



    const sel = (selectedRows.length > 0)
    return (
        <div className="TableOverview" key="tableOverview">
            {rows && <div key="dragtable">
               {title && (<div className='title'>{title}</div>)}
                <div className="actions">
                    {handleAdd && <Button text={'Toevoegen'} click={handleAdd} />}
                    {handleDelete && <Button   text={'Verwijder'} active={sel} click={sel ? handleDelete : () => { }} />}
                    <Button text={'Kolommen'} style="alternate" click={() => { setShowModal(true) }} />
                    {showModal && createPortal(
                        <Popup title="Set visible columns" content={modalContent} size="small" onClose={() => setShowModal(false)} />,
                        document.body
                    )}
                </div>
                <div key="scroll-bar-container" className="scroll-container">
                    <table>
                        <thead>
                            <tr>
                                {handleDelete && <th style={{ width: '50px' }}></th>}
                                <th style={{ width: '50px' }}></th>
                                {headers.map((h, i) => {
                                    return <th key={'header' + i} style={{ width: 'auto'/*h[1]+'px'*/, }}><div><span>{h[1]}</span><i>:</i></div></th>
                                })}
                                {options && <th></th>}
                            </tr>

                        </thead>
                        <tbody>
                            {rows.map((data, index) => {
                                const project = data 
                                if (!project.id) {
                                    return
                                }

                                return (
                                    <tr className={`drag-row ${!draggedRow && 'static'} ${(highlight===index) && 'visited'}`}
                                        key={'tablenumber' + project.id}
                                        style={draggedRow === project ? { background: '#bbb', top: topOffset[1] + 'px', position: 'relative', zIndex: 10 } : {}}
                                        onClick={(e) => { handleClick(e, project, index) }}
                                    >
                                        <td className={"drag-handle " + (draggedRow === project ? 'active' : '')}
                                            draggable={true}
                                            onDragStart={(e) => handleDragStart(e, project)}
                                            onDrag={(e) => { handleDragOver(e, index) }}
                                            onDragEnd={handleDrop}
                                            key={project.id}
                                            style={draggedRow === project ? { cursor: 'grab' } : {}}
                                            onClick={(e) => e.stopPropagation()}

                                        ><div />
                                            <div className="border-element">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="grey">
                                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                                                </svg>
                                            </div>
                                        </td>
                                        {handleDelete && <td>
                                            <Checkbox
                                                checked={selectedRows.includes(project.id)}
                                                onChange={(e) => handleCheckboxChange(e, project.id)}
                                            />
                                        </td>}
                                        {getRowContent(project)}
                                        {options && (() => {

                                            const opt = [...options.map(e => {
                                                if (e) {
                                                    return { title: e.title, func: (ev) => { e.func(ev, project.id) } }
                                                }

                                            })]
                                            if (handleDelete) {
                                                opt.push({
                                                    func: (e) => {
                                                        handleDelete(e, project.id);
                                                    },
                                                    title: 'Delete'
                                                })
                                            }
                                            if (handleClick) {
                                                opt.unshift({
                                                    func: (e) => {
                                                        handleClick(e, project, data.fields);
                                                    },
                                                    title: 'Open'
                                                })
                                            }
                                            return (<td><DropdownMenu key={'dropdown' + project.id} content={opt} /></td>)
                                        })()
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>}
        </div>

    );
};


function isObject(A) {
    if ((typeof A === "object" || typeof A === 'function') && (A !== null)) {
        return true
    }
}
function isArray(A) {
    return Array.isArray(A)
}
import { useState, useRef, useEffect } from 'react';
import { PageLine } from '../pageline.js';
import { CollectionObject, DocumentObject } from '../../firebase/firebase-collection.js';
import { DragTable } from '../table.js';
import { Form } from './form.js';

import './formCollection.css';
import { arrayRemove } from 'firebase/firestore';
import { createPortal } from 'react-dom';
import { Popup } from '../Popup';
import { AdminDatabase } from '../../firebase/firebase-admin.js';
import { useMemo } from 'react';
//import { read, utils, writeFile } from 'xlsx';
const fields = [
    { id: "name", name: "name", type: 'TextBox', defaultValue: "new Project Type", columnVisible: true },
    { id: "type", name: "type", type: 'TextBox', defaultValue: "Project", columnVisible: true },
    { id: "id", name: "id", type: 'fixed', defaultValue: 9999, columnVisible: false },
    { id: "amount", name: "amount", type: 'fixed', defaultValue: 0, columnVisible: false },
    { id: "index", name: "index", type: 'fixed', defaultValue: 0, columnVisible: false }
]

/*

export function RunSomeCode({ data }) {
    const defaultFields = {
        "name": 'Project-Types',
        "index": 0,
        "id": 'projects',
        "amount": 0,
    }
    const projectFields = [
        { name: "titel", type: 'TextBox', defaultValue: "New Project", columnVisible: true, index: 0 },
        { name: "jaar", type: 'YearPicker', defaultValue: 2000, columnVisible: true, index: 1 },
        { name: "materiaal", type: 'MultipleTextBox', defaultValue: [''], columnVisible: false, index: 2 },
        { name: "formaat", type: 'TextBox', defaultValue: "", columnVisible: false, index: 3 },
        { name: "auteur", type: 'TextBox', defaultValue: "", columnVisible: false, index: 4 },
        { name: "beschrijving", type: 'LargeTextBox', defaultValue: "", columnVisible: false, index: 5 },
        { name: "producent", type: 'TextBox', defaultValue: "", columnVisible: false, index: 6 },
        { name: "fotos", type: 'UploadImages', defaultValue: [], columnVisible: false, index: 7 },
        { name: "groep", type: 'GroupTextBox', defaultValue: [], columnVisible: false, index: 8 },
    ]
    const fields = [
        { id: "name", name: "name", type: 'TextBox', defaultValue: "new Project Type", columnVisible: true },
        { id: "type", name: "type", type: 'TextBox', defaultValue: "Project", columnVisible: true },
        { id: "id", name: "id", type: 'fixed', defaultValue: 9999, columnVisible: false },
        { id: "amount", name: "amount", type: 'fixed', defaultValue: 0, columnVisible: false },
        { id: "index", name: "index", type: 'fixed', defaultValue: 0, columnVisible: false }
    ]
    const imagefields = [
        { name: "fotograaf", type: 'TextBox', defaultValue: "", columnVisible: false },
        { name: "url", type: 'TextBox', defaultValue: "", columnVisible: false },

    ]
  
    console.log(data)
    useEffect(function () {
        (async function () {
            /*
            imagefields.forEach(d => {
                console.log(d)
                DATA.addDoc('images/entries/ylG2BLfStQCJTEA2ScrU/fields', d)
            })
            
            //  await setFields()
            await setEntries()
            async function setEntries() {
                const f = await AdminDatabase.getCollection('projects/entries/Ik6CTcOxtrLOyJC1yzWo/fields');
                const _D = []
                f.forEach(ff => {
                    const d = ff.data()
                    data.forEach((dd, i) => {
                        if (!_D[i]) {
                            _D[i] = {}
                        }
                        _D[i][d.id] = dd[d.name]
                    })

                })
                _D.forEach(d => {
                    console.log(d)
                    AdminDatabase.addDoc('projects/entries/Ik6CTcOxtrLOyJC1yzWoBV/entries', d)
                })
            }
            async function setFields() {
                projectFields.forEach(d => {
                    console.log(d)
                    AdminDatabase.addDoc('projects/entries/Ik6CTcOxtrLOyJC1yzWo/fields', d)
                })
            }
        })()
    }, [])

    return (<div></div>)
}

*/

function Container({ children }) {
    return (
        <div style={{  }}>
            <PageLine />
            {children}
            <PageLine />
        </div>

    );
};


export function Collection({ title, path, click, filter, onUpdate, options=[],target,fields}) {
    console.log('check')
    const data = CollectionObject({ path, filter,onUpdate,target,fields})
    console.log(data)
    return (
        <div>
            <Container>
                {data &&
                    <DragTable
                        key={title + '-list'}
                        click={click}
                        options={options}
                        title={title}
                        data={data} 
                        />
                }
            </Container>
        </div>
    )
}

export function CollectionFields({ path, id, title }){
console.log('fields: '+path)
    const defaultFields = [
        { id: 'name', name: 'Admin Naam', type: "TextBox", defaultValue: '', columnVisible: true, index: 0 },
        { id: 'type', name: 'Type', type: "DropdownList", defaultValue: "TextBox", columnVisible: true, index: 1 },
        { id: 'id', name: 'ID', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'index', name: 'Index', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'columnVisible', name: 'Kolom zichtbaar', type: 'CheckboxField', defaultValue: true, columnVisible: true, index: 3 },
        { id: 'defaultValue', name: 'Standaard Waarde', type: "TextBox", defaultValue: null, columnVisible: true, index: 2 },
    ]

    const [modalContent, setModalContent] = useState(null)
    function click(e, p, f) {
        setModalContent(e)
    }
 

    return(<div>
          {title && (<div className='title'>{title}</div>)}
    {!modalContent && <Collection path={path} click={click} target="fields" fields={defaultFields}/>}


    {modalContent && 
    //createPortal(
        //<Popup title="Project fields" onClose={() => setModalContent(null)} content={(
            <DocumentForm
                key={'fields-form-list'}
                title={"Project Velden"}
                id={modalContent}
                fields={defaultFields}
                path={path}
                close={() => setModalContent(null)} 
                target="fields"/>
       // )} />,
      //  document.body)
    }
    </div>)
}

export function DocumentForm({ buttons,state,title, path, id,fields,target,type,onUpdate,close}) {
    const data = DocumentObject({ title,path, id,fields,target,type,onUpdate})
    return (
        <div>
            {data && <Form
            state={state}
                key={title + '-form-list'}
                title={title}
                data={data}
                close={close}
                parent={{ document: id }} 
                buttons={buttons}/>
            }
        </div>
    )
}


/*
export function CollectionFields({ path, id, title }) {
    const [modalContent, setModalContent] = useState(null)
    const [data, setData] = useState(null)
    const [state, setState] = useState(null)
    const truePath = path + '/entries/' + id

    const defaultFields = [
        { id: 'name', name: 'Admin Naam', type: "TextBox", defaultValue: '', columnVisible: true, index: 0 },
        { id: 'type', name: 'Type', type: "DropdownList", defaultValue: "TextBox", columnVisible: true, index: 1 },
        { id: 'id', name: 'ID', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'index', name: 'Index', type: 'fixed', defaultValue: null, columnVisible: false },
        { id: 'columnVisible', name: 'Kolom zichtbaar', type: 'CheckboxField', defaultValue: true, columnVisible: true, index: 3 },
        { id: 'defaultValue', name: 'Standaard Waarde', type: "TextBox", defaultValue: null, columnVisible: true, index: 2 },

    ]

    useEffect(function () {
        (async function () {
            console.log(truePath)
            const f = await AdminDatabase.getCollection(truePath + '/fields')
            setData(f)

        })()
    }, [path, state]);

    function click(e, p, f) {
        setModalContent(p)
    }
    function add() {
        return (async function () {
            const d = {};
            defaultFields.forEach(e => {
                if (e.defaultValue !== undefined) {
                    d[e.id] = e.defaultValue;
                }
            })
            const k = await AdminDatabase.addDoc(truePath + '/fields', d)
            d.id = k.id
            d.index = (data && data.length) ? data.length : 0

            return d
        })()
    }
    function _delete(pjId) {
        return (async function () {
            const ar = (Array.isArray(pjId)) ? pjId : [pjId]
            await AdminDatabase.deleteDocs(truePath + '/fields', ar)
        })()

    }
    function update(data) {
        return (async function () {
            await AdminDatabase.updateDocs(truePath + '/fields', data)
        })()
    }

    function formUpdate(d) {
        return (async function () {
            setModalContent(d)
            await AdminDatabase.updateDoc(truePath + '/fields', modalContent.id, d)
            setState(Math.random())
        })()
    }

    console.log(data)

    return (
        <div>
            {title && (<div className='title'>{title}</div>)}
            <CollectionForm path={path} id={id} />
            {data && <DragTable
                key={'fields-list'}
                click={click}
                data={{ data, fields: defaultFields, delete: _delete, add, update }} />
            }

            {modalContent && createPortal(
                <Popup title="Project Velden" onClose={() => setModalContent(null)} content={(
                    <Form
                        update={formUpdate}
                        key={'fields-form-list'}
                        title={"Project Velden"}
                        data={modalContent}
                        fields={defaultFields}
                        close={() => setModalContent(null)} />
                )} />,
                document.body
            )}

        </div>
    )
}



export function CollectionForm({ title, path, id, close }) {

    function _update(data) {
        return (async function () {
            await AdminDatabase.updateDoc(path + '/entries', id, data)
        })()
    }
    const [data, setData] = useState(null)
    const [fields, setFields] = useState(null)

    useEffect(() => {
        (async function () {
            const f = await AdminDatabase.getCollection(path + '/fields')
            if (f.empty) {
                console.error('ERROR NO FIELDS FOUND FOR FORM')
            }
            setFields([...getDefaultFields(title), ...f])
            const e = await AdminDatabase.getDoc(path + '/entries', id)
            setData(e)
        })()
    }, []);

    return (
        <div>
            {(fields && data) && <Form
                update={_update}
                key={title + '-form-list'}
                title={title}
                data={data}
                fields={fields}
                close={close}
                parent={{ collection: id }} />
            }
        </div>
    )
}
export function DocumentForm({ title, path, id, close }) {

    function _update(data) {
        return (async function () {
            await AdminDatabase.updateDoc(path + '/entries', id, data)
        })()
    }
    const [data, setData] = useState(null)
    const [fields, setFields] = useState(null)

    useEffect(() => {
        (async function () {
            const f = await AdminDatabase.getCollection(path + '/entries/' + id + '/fields')
            if (f.empty) {
                console.error('ERROR NO FIELDS FOUND FOR FORM')
            }
            setFields([...getDefaultFields(title), ...f])

            const e = await AdminDatabase.getDoc(path + '/entries', id)
            setData(e)
        })()
    }, []);

    return (
        <div>
            {(fields && data) && <Form
                update={_update}
                key={title + '-form-list'}
                title={title}
                data={data}
                fields={fields}
                close={close}
                parent={{ document: id }} />
            }
        </div>
    )
}




/*

function UpdateExcel(){



    re
}

function UploadExcel() {

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                   console.log(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    return (
        <div className="input-group">
            <div className="custom-file">
                <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
            </div>
        </div>
    )
}

function DownloadExcel(){
    const handleExport = () => {
        const headings = [[
            'Movie',
            'Category',
            'Director',
            'Rating'
        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, movies, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Movie Report.xlsx');
    }

    return(
        <div className="col-md-6">
        <button onClick={handleExport} className="btn btn-primary float-right">
            Export <i className="fa fa-download"></i>
        </button>
    </div>
    )
}*/

import { UploadImage } from '../../firebase/firebase-uploadImage.js';
import { DragTable } from '../table.js';
import { CustomTable, FormField, LargeTextBox, MultipleTextBox, TextBox, YearPicker } from './inputfields.js';
import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Popup } from '../Popup.js';
import { Form } from './form.js';
import { Collection, CollectionForm, DocumentForm } from './formCollection';
import { Button } from '../Buttons.js';
import { AdminDatabase } from '../../firebase/firebase-admin.js';


export function UploadImages({ value, label, update, parent }) {
    const v = (value && !Array.isArray(value)) ? value : { 'ylG2BLfStQCJTEA2ScrU': [] }

    const [typeState, setTypeState] = useState(Object.keys(v)[0])
    const [state, setState] = useState(Object.values(v)[0])
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(false);

    function setValues(data) {
        const d = {};

        d[typeState] = data;
        console.log(d[typeState])
        /*
       (async function () {
                await AdminDatabase.updateDoc('images/entries/' + typeState, id, data)
        })()*/
        update(d)
        setState(data)
    }

    function close() {
        setShowModal(false);
        setModalContent(false);
    }

    function click(e, data, fields) {
        setShowModal(true)
        setModalContent((
            <div>
                <DocumentForm path={'images/entries/' + typeState} id={e} close={close} type={'collection'} />
            </div>)
        )
    }
    return (
        <div className="project-images-upload">
            {showModal && createPortal(
                <Popup title="Select Image" content={modalContent} onClose={close} />,
                document.body
            )}
            <Collection title="Images" path={'images/entries/' + typeState} id={typeState} onUpdate={setValues} setFilter={setValues} filter={['id', 'in', state]} click={click} />
        </div>
    )
}

export function Image({ label, value, update, parent }) {
    const v = (value && (typeof value !== 'string')) ? value : { 'ylG2BLfStQCJTEA2ScrU': undefined }

    const [typeState, setTypeState] = useState(Object.keys(v)[0])
    const _id = Object.values(v)[0]
    const [id, setId] = useState(_id)
    const [state, setState] = useState((_id))
    const [lib,setLib]= useState(false)
    const [url, setUrl] = useState()


    function setValue(id, data) {
        console.log('imagid: ' + id)
        const d = {};
        d[typeState] = id;
        update(d)
        setId(id)
    }

    function ImageList(){
        return (
         createPortal(
        <Popup 
            title="Warning" 
            size="small"
            onClose={() => setLib(false)} 
            content={(<Collection key={"images"}  path={'images/entries/ylG2BLfStQCJTEA2ScrU'}  />
            
        )} />,
        document.body)
        )
    }

    return (
        <div className="project-images-upload">
            <div className='btn-container' >
                <div />
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Button text={'New Image'} click={() => { setId(false);setState(true) }} />
                    <Button text={'From Library'} click={()=>{ setLib(true)}} />
                </div>


            </div>
            {state && <DocumentForm key={'form'+id} state={'view'} buttons={{ bottom: false, top: true }} type={"collection"} title="image" path={'images/entries/' + typeState} id={id} close={() => { }} onUpdate={setValue} />}
            {lib && <ImageList/>}
        </div>
    )
}

export function ImageUpload({ label, value = '', update, parent, disabled }) {
    console.log(disabled)
    if (typeof value === 'string') {
        value = {
            original: '',
            large: '',
            medium: '',
            small: ''
        }
    }
    const [file, setFile] = useState()
    const [name, setName] = useState()
    const [urls, _setUrls] = useState(value)
    const types = ['image/jpeg', 'image/png', 'image/bmp', 'image/webp', 'image/gif'];

    function changeHandler(e) {
        let selected = e.target.files[0]
        if (selected && types.includes(selected.type)) {
            setFile(selected)
        } else {
            setFile(null)
        }
    }
    function setUrls(urls) {
        _setUrls(urls)
        update(urls)
    }

    useEffect(() => {
        if (file) {
            const sp = file.name.split('.');
            const name = sp.join()
            setName(name)
        }
    }, [file])
    return (
        <FormField  state="column">
            <div>

                {false && name && <div>Name: {name}</div>}

                <div >
                    <div style={{ display: 'flex' }}>
                    {!disabled && (
                        <div>
                            <Button htmlFor="fileUpload" text={((urls) ? "Change" : "Upload") + ' Image'} />
                            <div>
                                <input disabled={disabled} id="fileUpload" style={{ display: 'none' }} type="file" class="file-uploader" onChange={changeHandler} />
                            </div>
                        </div>
                    )}
                    {file && <ProgressBar file={file} setUrls={setUrls} name={Object.values(parent)} />}
                    </div>
                    <div style={{ background: '#e7e7e7', padding: '10px', display: 'flex',justifyContent: 'center'}}>
                        {(urls.original.length > 0) ? <img style={{ width: '100%', maxWidth: '25vw' }} src={urls.medium} /> : <div style={{ padding: '20px 30px' }}>No Image</div>}
                    </div>
                </div>
            </div>
        </FormField>
    )

}

function ProgressBar({ file, setUrls, name }) {
    const { url: url_original, progress: progress_original, error: error_original, id: id_original } = UploadImage({ file, name: name + '_original' })//
    const { url: url_large, progress: progress_large, error: error_large, id: id_large } = UploadImage({ file, size: 1920, name: name + '_large' })//
    const { url: url_medium, progress: progress_medium, error: error_medium, id: id_medium } = UploadImage({ file, size: 800, name: name + '_medium' })//
    const { url: url_small, progress: progress_small, error: error_small, id: id_small } = UploadImage({ file, size: 250, name: name + '_small' })//
    const [status, setStatus] = useState('in Progress...')
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        const prog = (progress_original + progress_large + progress_medium + progress_small) / 4;
        setProgress(prog)

    }, [progress_original, progress_large, progress_medium, progress_small])

    useEffect(() => {
        if (url_original && url_large && url_medium && url_small) {
            setUrls({
                original: url_original,
                large: url_large,
                medium: url_medium,
                small: url_small
            })
            setStatus('Complete');
        }
    }, [url_original, url_small, url_large, url_medium])

    return (
        <div className="progress-bar" style={{ padding: '2%',width: '100%' }}>
            <div style={{ background: '#eee', width: '100%', height: '30px', position: 'relative', borderRadius: '40px', overflow: 'hidden' }}>
                <div style={{ transition: 'width linear 0.2s', width: progress + '%', height: '100%', background: 'rgb(78 207 82)', position: 'absolute' }} />
                <div className="progress-text">
                    {status + ' ' + progress + '%'}
                </div>
            </div>
        </div>

    )
}




function Images({ dat, changeIndex }) {
    const [data, setData] = useState(dat)
    const [dragHeight, setHeight] = useState(0)

    const ref = useRef()


    return (
        <div className="image" ref={ref}>
            <img src={data.url} />

        </div>
    );
}



import './dropdown.css';
import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
export function DropdownMenu({ content }) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null)






    return (

        <div className="dropdown">
            <button ref={ref} onClick={(e) => {
                setIsOpen(!isOpen);
                e.stopPropagation()

            }}>  <span>&#8942;</span></button>
            {isOpen && createPortal(
                <DropwDownContent close={() => { setIsOpen(false) }} key='henkie' content={content} butRef={ref} />, document.body)
            }
        </div>

    );
}

function DropwDownContent({ content, butRef, close }) {

    const [pos, setPos] = useState(null)
    useEffect(() => {
        function setHeight() {
            if (butRef.current) {
                const d = butRef.current.getBoundingClientRect();
                const y = d.y + d.height;
                const x = (window.innerWidth / 2 < d.left) ? d.x - (160 - d.width) : d.x
                setPos([x, y])

            }
        }
        setHeight()
        const scroll = document.querySelector('.TableOverview .scroll-container');
        scroll.addEventListener('scroll', setHeight)
        return () => { scroll.removeEventListener('scroll', setHeight) }

    }, [])

    useEffect(() => {
        function click(e) {
            close()
            e.stopPropagation()
            e.preventDefault()
        }
        function rightClick(e) {

            e.preventDefault();
            close()
            return false;

        }

        window.addEventListener('click', click)
        window.addEventListener('contextmenu', rightClick, false);


        return () => { window.removeEventListener('click', click); window.removeEventListener('contextmenu', rightClick) };
    }, [])
    return (
        <div>
            {pos && (
                <div className="dropdown-menu" style={{ top: pos[1] + 'px', left: pos[0] + 'px' }} >
                    {content.map((c, i) => {
                        if (c) { return (<a key={"dropdownContent" + i} onClickCapture={(e) => { e.stopPropagation(); c.func(e) }}>{c.title}</a>) }

                    })}
                </div>
            )}
        </div>
    )
}
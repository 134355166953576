
import * as firebase from "firebase/app";
import { getStorage} from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


// Initialize Firebase
 const FirestoreApp = firebase.initializeApp(firebaseConfig);
 
 export const firebaseStorage = getStorage(FirestoreApp)
 export const firebaseDatabase = getFirestore(FirestoreApp)
 const api = process.env.REACT_APP_API_URL
 console.log(api)
 const url = (api.slice(-1)==="/")?api:api+"/"
 const storageUrl = url+ process.env.REACT_APP_STORAGE_BUCKET+"/o/"
 export function getImageUrl(id,size){
    return storageUrl+'images%2F'+id+"_"+size+"?alt=media"
 }

 


  import React, { useEffect, useRef } from 'react';
  import Quill from 'quill';
  import 'quill/dist/quill.snow.css';
  
export function QuillBox ({value,onchange,disabled=false}) {
    const editorRef = useRef(null);
  
    useEffect(() => {
      const editor = new Quill(editorRef.current, {
        theme: 'snow',
        readOnly: disabled,
        modules: {
          toolbar: {
            container: [
              [{ font: [] }, { size: [] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['link', 'image', 'video'],
              ['clean']
            ],
            handlers: {
              // handler functions
            }
          }
        }
      });
      if(value){editor.clipboard.dangerouslyPasteHTML(value)}
      editor.on('text-change', (e,s,p) => {
       onchange(editor.root.innerHTML);
      });
  
      return () => {
        editor.off('text-change');
      };
    }, []);
  
    return <div ref={editorRef} />;
  };
  

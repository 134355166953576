import * as firebase from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, setDoc, query, getDoc, updateDoc, writeBatch, doc, where, deleteDoc, addDoc, getDocs, QuerySnapshot, serverTimestamp } from 'firebase/firestore';
import imageCompression from 'browser-image-compression';
import { useState, useEffect } from 'react';
import { firebaseStorage } from "./firebase-verify";


export function UploadImage({ file, size, name }) {
    const [storageRef, setStorageRef] = useState(null);
    const [compressed, setCompressed] = useState(null);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        (async () => {
            console.log('one of the images',file,size)
            ///compressImage
            if (!size) {
                setCompressed(file)

            } else {
                const options = {
                    onProgress: (p) => {
                        setProgress(p / 2)
                    },
                    maxSizeMB:  1, // Maximum file size
                    maxWidthOrHeight: size, // Maximum side size
                    useWebWorker: true,
                    fileType: 'image/jpeg' ,  // Output format
                };
                try {
                    const compressedile = await imageCompression(file, options);
                    setCompressed(compressedile)

                } catch (error) {
                    console.error('Compression failed:', error);
                }
            }
            ///get storage reference
            await getRef();

            async function getRef(i = 0) {
                const sref = ref(firebaseStorage, '/images/' + name);
                if (sref) {
                    setStorageRef(sref);
                    return;
                }
                getRef(++i);
            }


        })();
    }, [file]);

    useEffect(() => {
        if (storageRef&&compressed) {
            const uploadTask = uploadBytesResumable(storageRef, compressed, {
                cacheControl: 'public, max-age=31536000',  // Cache for one year
            });

            uploadTask.on('state_changed', (snap) => {
                let perc = (snap.bytesTransferred / snap.totalBytes) * 50;
                console.log(perc)
                setProgress(50 + perc);
            }, (err) => {
                setError(err);
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setUrl(downloadURL);
                    setId(uploadTask.snapshot.metadata.md5Hash);
                });
            });
        }
    }, [storageRef,compressed]);

    return { progress, url, error, id };
}

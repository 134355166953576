import {Button, Collection,CollectionFields,CollectionForm } from './resources/index';
import './projects.css';

import {useEffect, useState} from 'react'
import { ControlImages } from './resources/firebase/firebase-management';
export function CMSImages() {
    const [state, setState] = useState(null)
    return (
        <div className="project-types">
            <div>
            <Button click = {()=>{setState(true)}} text="check images" />
           
            {(!state ) && (
                    <Collection key={"images"}  path={'images/entries/ylG2BLfStQCJTEA2ScrU'}  />
            )}
</div>
        </div>
    )
}

/* {state && <ControlImages/>}

            */
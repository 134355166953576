import './checkbox.css';

export function Checkbox({value,float="start", type = 'standard', checked, onChange }) {
    return (
        <div      onClick={(e)=>e.stopPropagation()} style={{ display: 'flex', alignItems: 'center',justifyContent:float, margin: '10px' }}>
            <label className={"checkbox " + type}>
                <input
                    value={value}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
               
                />
                <div className='element' />
            </label>
            <span style={{ marginLeft: '10px' }}>{value}</span>
        </div>
    )
}

import { useState, useEffect, useRef } from 'react';
import { AddIcon, DeleteIcon } from '../Buttons.js';
import { Checkbox } from '../checkbox.js';
 

import{ QuillBox } from './Quill.js';
export function FormField({label,children,state,lfor}) {

    return (
        <div className="form-element">
            <div className={"form-grid "+((state)?state:'')}>
                {label && <label htmlFor={lfor} class='form-label'>
                    <div className="label-title">
                        {label}
                    </div>
                    <div className="label-title">:</div>
                </label>}
       
               
                <div className="text-box">
                    {children}
                </div>
            </div>
        </div>

    )
}


export function YearPicker({ label, value, update,disabled }) {
    const [state, setState] = useState(value)
    const [ changed,setChanged] = useState(false)
    const now = new Date().getFullYear()
    function getOptions() {
        const k = []
        for (let i = now; i >= 1970; i--) {
            const string = i.toString()
            k.push(<option value={string}>{string}</option>)
        }
        return (k)
    }

    function handleChange(e) {
        setState(e.target.value);
        update(e.target.value);
        setChanged((e.target.value !== value))
    }

    return (
        <FormField label={label}>
            <select disabled={disabled} className={`form-input ${(changed)?'changed':''}`} value={state} onChange={handleChange}>
                {getOptions()}
            </select>
        </FormField>
    );

}

export function TextBox({ label, value, update ,disabled}) {
    const [state, setState] = useState(value)
    const [ changed,setChanged] = useState(false)
    function handleChange(e) {
        const b = e.target.value
        setState(b);
        update(b)
        setChanged((b !== value))
    }

    return (
        <FormField label={label}>
                <input disabled={disabled} className={`form-input ${(changed)?'changed':''}`} id={label} value={state} type="text" name={label} onChange={handleChange} />
        </FormField>
    );

}
export function CheckboxField({ label, value, update,disabled }) {
    const val = (value)
    const [state, setState] = useState(val)
    const [changed,setChanged] = useState(false)
    function handleChange(e) {
        const b = e.target.checked
        setState(b);
        update(b)
        setChanged((b !== value))
    }

    return (
        <FormField label={label}>
                <Checkbox disabled={disabled} id={label} checked={(state)} onChange={handleChange}/>
        </FormField>
    );

}

export function DropdownList({ label, value, update,options,disabled }) {
    const [state, setState] = useState(value)
    const [changed,setChanged] = useState(false)
    const handleChange = (event) => {
      const b = event.target.value;
      setState(b)
      update(b);
      setChanged((b !== value))

    };
    
 
    return (
        <FormField label={label}>
        <select disabled={disabled}className={`form-input ${(changed)?'changed':''}`} id={label} value={state} onChange={handleChange}>
            {options.map(o=>{
                return (<option value={o}>{o}</option>)
            })}
        </select>
        </FormField>
    );
  }
  
///////////////////////
export function LargeTextBox({ label, value, update,disabled }) {
    const [state, setState] = useState(value)
    const [changed,setChanged] = useState(false)
    function handleChange(e) {
        const b = e.target.value
        setState(b);
        update(b)
        setChanged((b !== value))
    }
    return (
        <FormField label={label}state={'column'}>
            <div class="large-area">
                <textarea disabled={disabled} className={`form-input ${(changed)?'changed':''}`} rows="5" cols="80" value={state} id={label}  onChange={handleChange}></textarea>
            </div>
        </FormField>
    );

}
/////////////////////
export function MultipleTextBox({ label, value, padding, update,disabled }) {
    console.log(value,Array.isArray(value));
    if(!Array.isArray(value)){
        if(value){
            value = [value.toString()]
        }else{
            value=['']
        }
            
        
    }

    const [state, setState] = useState(value)

    function handleRemove(e, i) {
        const ns = state.slice()
        ns.splice(i, 1)
        setState(ns)
    }

    function handleAdd(e) {
        const ns = state.slice()
        ns.push('')
        console.log(state)
        setState(ns);
    }
    function handleChange(e, i) {
        const d = state.slice()
        d[i] = e.target.value
        setState(d);
        update(d)
    }

    return (
        <FormField label={label}>

            <div>
                {state && state.map((e, i) => {
                    return (
                        <div className='input-add' >
                            <input disabled={disabled} className="form-input" value={e} type="text" name={label} onChange={(e) => { handleChange(e, i) }} />
                            {!disabled && <DeleteIcon click={(e) => { handleRemove(e, i) }} />}
                        </div>)
                })
                }
                {state && (
                <div style={{ justifyContent: 'end', margin: '4px 0px' }}>
                  {!disabled &&  <AddIcon key={state[0]}  click={handleAdd} />}
                </div>)
                }
            </div>
        </FormField>
    );

}

export function GroupTextBox({ label, value, update,disabled }) {
    if (!Array.isArray(value)) {
        value = [{ label: '', value: [''] }]
    }
    console.log(value)
    const [state, setState] = useState(value)
    function handleRemove(e, i) {
        const ns = state.slice()
        ns.splice(i, 1)
        setState(ns)
    }


    function handleAdd(e) {
        const ns = state.slice()
        ns.push({ label: '', value: [''] })
        console.log(state)
        setState(ns);
    }
    function handleChange(e, i) {
        const d = state.slice()
        d[i].value = e
        setState(d);
        update(d)
    }
    function handleLabel(e, i) {
        const d = state.slice()
        d[i].label = e.target.value
        setState(d);
        update(d)
    }
    return (
        <FormField label={label} state={'column'}>
            <div>
                {state.map((s, i) => {
                    const la = s.label;
                    const va = s.value;
                    const labl = [(
                        <div className='input-add' style={{marginRight:'6px',minWidth:'min(30vw,400px)'}}>
                            <DeleteIcon click={(e) => {handleRemove(e, i) }} />
                            <input disabled={disabled} className="form-input" value={la} type="text" name={label} onChange={(e) => { handleLabel(e, i) }} />

                        </div>)]

                    return <MultipleTextBox disabled={disabled} update={(e) => { handleChange(e, i) }} key={'textbox' + i} label={labl} value={va} padding={'0px'} />
                })}
                <div  style={{ justifyContent: 'start', margin: '4px 0px' }}>
                <AddIcon type='+' click={handleAdd} />
                </div>
               
            </div>
        </FormField>
    );

}

export function HTMLTextBox({ label, value, update,disabled }) {
    const [state, setState] = useState(value)
    function handleChange(e) {
        setState(e);
        update(e)
    }
    return (
        <FormField label={label}state={'column'}>
            <div class="large-area">
                <QuillBox value={state} onchange={handleChange} disabled={disabled}/>
            </div>
        </FormField>
    );

}



import './App.css';

import { Admin } from './admin';
import { controlImages } from './resources/firebase/firebase-management';
export default function App() {

    return (
        <div className="App">
            <div className="background" />

                <section className="sect-mid">
                    <Admin />
                </section>

        </div>

    );
}

// Import the functions you need from the SDKs you need

import { getFirestore, collection, setDoc, query, getDoc, updateDoc, writeBatch, doc, where, deleteDoc, addDoc, getDocs, QuerySnapshot, serverTimestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { firebaseDatabase } from "./firebase-verify";
//import {getFirestore} from 'firebase/firestore'

//import {getAuth, GoogleAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


export const AdminDatabase = {
    async getCollection(path, filter /*where("capital", "==", true)*/) {

        const p = 'data/' + path
        const wher = (filter) ? where(...filter) : undefined
        const q = (wher) ?
            query(collection(firebaseDatabase, p), wher) :
            collection(firebaseDatabase, p);
        const querySnapshot = await getDocs(q);

        const data = await transpose(querySnapshot)
        return data

        async function transpose(k) {
            const obj = []
            k.forEach(e => {
                obj.push(e.data())
            })
            return obj
        }
    },

    async getDoc(path, id) {
        const p = 'data/' + path;
        const docs = doc(firebaseDatabase, p, id)
        const querySnapshot = await getDoc(docs);
        const d = querySnapshot.data()
        return d
    },
    async addDoc(path, data) {
        data.id = null
        const col = 'data/' + path;
        const docs = collection(firebaseDatabase, col);
        const s = await addDoc(docs, data);
        data.id = s.id
        const u = await this.updateDoc(path, s.id, { id: s.id })
        return s
    },
    async addDocs(path, data) {
        data.forEach(async d => {
            const s = await this.addDoc(path, d)
        })
        return
    },
    async deleteDoc(path, id) {
        const p = 'data/' + path;
        const docs = doc(firebaseDatabase, p, id)
        await deleteDoc(docs);
    },
    async deleteDocs(path, id) {
        const p = 'data/' + path;
        const batch = writeBatch(firebaseDatabase);
        id.forEach(i => {
            const docs = doc(firebaseDatabase, p, i)
            batch.delete(docs);
        })
        return await batch.commit();
    },
    async setDoc(path, id, data) {
        const col = 'data/' + path;
        const docs = doc(firebaseDatabase, col, id);
        const s = await setDoc(docs, data)
        return s
    },
    async setDocs(path, ids) {
        const batch = writeBatch(firebaseDatabase);
        const p = 'data/' + path;
        const k = Object.keys(ids)
        k.forEach(i => {
            const docs = doc(firebaseDatabase, p, i);
            batch.set(docs, ids[k]);
        })
        return await batch.commit();
    },
    async updateDoc(path, id, data) {
        const col = 'data/' + path;
        const docs = doc(firebaseDatabase, col, id);
        const dat = { ...data, timestamp: serverTimestamp() }
        checkUndefined(dat)
        const s = updateDoc(docs, dat);
        await s
        return true
    },
    async updateDocs(path, ids) {
        const batch = writeBatch(firebaseDatabase);
        const p = 'data/' + path;
        const k = Object.keys(ids)
        k.forEach(i => {
            const docs = doc(firebaseDatabase, p, i);
            batch.update(docs, ids[i]);
        })
        return await batch.commit();
    }

}
function checkUndefined(d) {
    const k = Object.keys(d)
    const kl = k.length
    for (let i = 0; i < kl; i++) {
        if (d[k[i]] === undefined) {
            d[k[i]] = null
        }
    }
}
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////

/*

export function CollectionObject({ folder, filter, defaultFields }) {
    const [data, setData] = useState(null)
    useEffect(() => {
        //  const path = (type)?folder + '/entries/' + type:folder;
        const path = folder;
        (async () => {
            const f = await AdminDatabase.getCollection(path + '/fields')
            const _data = (filter && filter[2].length === 0) ? [] : await AdminDatabase.getCollection(path + '/entries', filter);
            const fields = (defaultFields) ? [...defaultFields, ...f] : f
            setData(new Data(fields, _data.map(d => { return new Data(fields, d) })))
        })()
    }, [folder, filter])
    return data
}

export function DocumentObject({ folder, type, defaultFields }) {
    const [data, setData] = useState(null)
    useEffect(() => {
        const path = folder + '/entries';
        const p = path + '/' + type + '/fields';
        (async () => {
            const f = await AdminDatabase.getCollection(p)
            const d = await AdminDatabase.getDoc(path, type)
            const fields = (defaultFields) ? [...defaultFields, ...f] : f
            setData(new Data(fields, d));
        })()
    }, [folder, type])
    return data
}


export function DocumentObject(path, id, title) {
    const [data, setData] = useState(null);
    const [fields, setFields] = useState(null);

    useEffect(() => {
        (async function () {
            const f = await AdminDatabase.getCollection(path + '/entries/' + id + '/fields');
            if (f.empty) {
                console.error('ERROR NO FIELDS FOUND FOR FORM');
            }
            setFields([...getDefaultFields(title), ...f]);

            const e = await AdminDatabase.getDoc(path + '/entries', id);
            setData(e);
        })();
    }, [path, id, title]);

    const update = async (dataToUpdate) => {
        await AdminDatabase.updateDoc(path + '/entries', id, dataToUpdate);
    };

    const get = (str, i) => {
        if (!fields || !data) { return }
        const f = fields.find(field => field.name === str);
        if (!f) { return [] }

        if (Array.isArray(data)) {
            if (typeof i === 'undefined') { return }
            if (data[i] && data[i][f.id]) {
                return data[i][f.id];
            }
        } else if (data[f.id]) {
            return data[f.id];
        }
        return [];
    };

    return {
        data,
        fields,
        update,
        get
    };
}

export function CollectionObject({folder, filter, defaultFields}) {
    const [state, setState] = useState({
        fields: null,
        data: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fieldsFromDB = await AdminDatabase.getCollection(`${folder}/fields`);
                const mergedFields = defaultFields ? [...defaultFields, ...fieldsFromDB] : fieldsFromDB;

                let fetchedData = [];
                if (!filter || filter[2].length > 0) {
                    fetchedData = await AdminDatabase.getCollection(`${folder}/entries`, filter);
                }
                
                setState({
                    fields: mergedFields,
                    data: fetchedData,
                });
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        fetchData();
    }, [folder, filter, defaultFields]);

    const add = async () => {
        if (!state.fields) return;

        const entryData = state.fields.reduce((acc, field) => {
            if (field.defaultValue !== undefined) {
                acc[field.id] = field.defaultValue;
            }
            return acc;
        }, {});

        const doc = await AdminDatabase.addDoc(`${folder}/entries/`, entryData);
        setState(prevState => ({
            ...prevState,
            data: [...prevState.data, doc],
        }));
    };

    const deleteItem = async (projectIds) => {
        const ids = Array.isArray(projectIds) ? projectIds : [projectIds];
        await AdminDatabase.deleteDocs(`${folder}/entries`, ids);
        setState(prevState => ({
            ...prevState,
            data: prevState.data.filter(d => !ids.includes(d.id)),
        }));
    };

    const update = async (dataToUpdate) => {
        await AdminDatabase.updateDocs(`${folder}/entries`, dataToUpdate);
        setState(prevState => ({
            ...prevState,
            data: prevState.data.map(d => (dataToUpdate.id === d.id ? dataToUpdate : d)),
        }));
    };

    // Return an object with fields, data, and the methods
    return {
        fields: state.fields,
        data: state.data,
        methods: {
            add,
            delete: deleteItem,
            update,
        }
    };
}



export class CollectionObject extends Component {
    state = {
        fields: null,
        data: null,
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.folder !== prevProps.folder ||
            this.props.filter !== prevProps.filter ||
            this.props.defaultFields !== prevProps.defaultFields
        ) {
            this.fetchData();
        }
    }

    fetchData = async () => {
        try {
            const fieldsFromDB = await AdminDatabase.getCollection(`${this.props.folder}/fields`);
            const fields = this.props.defaultFields ? [...this.props.defaultFields, ...fieldsFromDB] : fieldsFromDB;

            let _data = [];
            if (!this.props.filter || this.props.filter[2].length > 0) {
                _data = await AdminDatabase.getCollection(`${this.props.folder}/entries`, this.props.filter);
            }
            this.setState({
                fields,
                data: _data,
            });
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };

    add = async () => {
        const { fields, data } = this.state;
        const entryData = fields.reduce((acc, field) => {
            if (field.defaultValue !== undefined) {
                acc[field.id] = field.defaultValue;
            }
            return acc;
        }, {});

        const doc = await AdminDatabase.addDoc(`${this.props.folder}/entries/`, entryData);
        this.setState({
            data: [...data, doc],
        });
    };

    delete = async (projectIds) => {
        const ids = Array.isArray(projectIds) ? projectIds : [projectIds];
        await AdminDatabase.deleteDocs(`${this.props.folder}/entries`, ids);
        this.setState(prevState => ({
            data: prevState.data.filter(d => !ids.includes(d.id)),
        }));
    };

    update = async (dataToUpdate) => {
        await AdminDatabase.updateDocs(`${this.props.folder}/entries`, dataToUpdate);
        this.setState(prevState => ({
            data: prevState.data.map(d => (dataToUpdate.id === d.id ? dataToUpdate : d)),
        }));
    };

    render() {
        const { data, fields } = this.state;
        return this.props.children({
            data,
            fields,
            add: this.add,
            delete: this.delete,
            update: this.update,
        });
    }
}

export default CollectionObject;


export function CollectionObject({ folder, filter, defaultFields }) {
    const [data, setData] = useState(null)
    useEffect(() => {
        //  const path = (type)?folder + '/entries/' + type:folder;
        const path = folder;
        (async () => {
            const f = await AdminDatabase.getCollection(path + '/fields')
            const _data = (filter && filter[2].length === 0) ? [] : await AdminDatabase.getCollection(path + '/entries', filter);
            const fields = (defaultFields) ? [...defaultFields, ...f] : f
            setData(new Data(fields, _data.map(d => { return new Data(fields, d) })))
        })()
    }, [folder, filter])
    return data
}

///Data Object
class Data extends Object {
    constructor(f, d) {
        super()
        this.fields = f
        this.data = d
    }
    get(str, i) {
        if (!this.fields || !this.data) { return }
        const f = this.fields.find(f => f.name === str)
        if (!f) { return [] }
        if (Array.isArray(this.data)) {
            if (!i && i !== 0) { return }
            if (this.data[i].data[f.id]) {
                return this.data[i].data[f.id]
            }
        }
        if (this.data[f.id]) {
            return this.data[f.id]
        }
        return []
    }
    async add() {
        const d = {};
        this.fields.forEach(e => {
            if (e.defaultValue !== undefined) {
                d[e.id] = e.defaultValue;
            }
        })
        const k = await AdminDatabase.addDoc(path + '/entries/', d)
        if (filter) {
            setFilter([...filter[2], d.id])
        }

        d.index = (data.data && data.data.length) ? data.data.length : 0
        d.id = k.id
        setOpen(k.id)
        return d

    }
    async delete(pjId) {
        const ar = (Array.isArray(pjId)) ? pjId : [pjId]
        changeFilter(ar)
        await AdminDatabase.deleteDocs(path + '/entries', ar)

        function changeFilter(projectId) {
            if (filter) {
                const f = filter[2].slice()
                projectId.forEach(pj => {
                    const ind = f.findIndex(e => e.id === projectId)
                    if (ind > -1) {
                        f.splice(ind, 1)
                    }
                })
                setFilter(f)
            }
        }
    }

    async update(data) {
        await AdminDatabase.updateDocs(path + '/entries', data)
    }


}*/


import './overview.css';
import {useState,useEffect,useRef} from 'react';
import { CSSTransition } from 'react-transition-group';



import { CMSProjects} from './Projects';
import { CMSSetup } from './Setup';
import { CMSImages } from './Images';


export function Admin({ data }) {

    return (
        <div style={{width:'100%',height: '100%'}}>
       <Dashboard data={data} />
        </div>
    );
}
function Card(props) {
    const style = {
        display: 'flex',
        width: '100%',
        maxWidth: '959px',
        justifyContent: 'center',
        margin: '2px auto'
    }
    return (<div className="card-box" style={style}>{props.children}</div>)
}

function Dashboard({ data, moveF }) {
    const [current,setCurrent] = useState('setup')
    const [state,setState] = useState('loaded')

    const handleTabClick = (page) => {
        setCurrent('none')
     
        setTimeout(()=>{setCurrent(page)},1)
    }

    return (
        <div className="CMSOverview">
            <div className="tabs">
                <button
                    className={current === 'setup' ? 'active' : ''}
                    onClick={() => handleTabClick('setup')}
                >
                    Setup
                </button>
                <button
                    className={current === 'projects' ? 'active' : ''}
                    onClick={() => handleTabClick('projects')}
                >
                    Projects
                </button>
                <button
                    className={current === 'images' ? 'active' : ''}
                    onClick={() => handleTabClick('images')}
                >
                   Images
                </button>

                    <a target="_blank" href="https://lisannebovee.nl">
                        See Website
                    </a>

            
            </div>
            <div className="main-content">

        {/*(state==='home') &&*/ (
            <CSSTransition
                in={(current==='setup'  && state==='loaded')}
                timeout={300}
                classNames="alert"
                unmountOnExit
                onExited={() => setState('loaded')}
            >
           <CMSSetup state={state}/>
            </CSSTransition>

        )}
        {/*(state==='projects') && */(
            <CSSTransition
                in={(current==='projects' && state==='loaded')}
                timeout={300}
                classNames="alert"
                unmountOnExit
                onExited={() => setState('loaded')}
            >
             <CMSProjects state={state}/>
            </CSSTransition>
        )}
         {/*(state==='projects') && */(
            <CSSTransition
            in={(current==='images' && state==='loaded')}
            timeout={300}
            classNames="alert"
            unmountOnExit
            onExited={() => setState('loaded')}
        >
         <CMSImages state={state}/>
        </CSSTransition>
    )}
         

            </div>
        </div>
    );
}


